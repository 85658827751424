/* DatePicker.module.css */
.datePicker {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 8px;
}

.datePicker:focus {
    outline: none;
    border-color: #007bff;

}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__input-container {
    width: 100%;
}

.react-datepicker__input-container input {
    width: 100%;
    padding: 0.5rem;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 0.25rem;

}

.react-datepicker__input-container input:focus {
    outline: none;
    border-color: #007bff;

}

.react-datepicker-popper {
    z-index: 9999 !important;
}

